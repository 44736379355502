var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("label", { attrs: { for: "accountManager" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "adminComponents.businessInfoForm.accountManager.label"
                  )
                ) +
                " "
            ),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
          _c("multiselect", {
            attrs: {
              id: "accountManager",
              options: _vm.accountManagers,
              placeholder: _vm.$t(
                "adminComponents.businessInfoForm.accountManager.placeholder"
              ),
            },
            model: {
              value: _vm.accountManager,
              callback: function ($$v) {
                _vm.accountManager = $$v
              },
              expression: "accountManager",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "form-group row mb-3" }, [
      _c("div", { staticClass: "mb-3 col-12" }, [
        _c("label", { attrs: { for: "notes" } }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.businessInfoForm.notes.label")) +
              " "
          ),
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.notes,
              expression: "notes",
            },
          ],
          staticClass: "form-control",
          attrs: {
            id: "notes",
            placeholder: _vm.$t(
              "adminComponents.businessInfoForm.notes.placeholder"
            ),
            rows: "3",
          },
          domProps: { value: _vm.notes },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.notes = $event.target.value
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
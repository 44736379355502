<script>
import { required, minValue, requiredIf } from 'vuelidate/lib/validators';
import CreditPlanCategories from "@/shared/enums/creditPlanCategories.js"
import contractTypesEnums from "@/shared/enums/contractTypes.js"

export default {
  data() {
    return {
      CreditPlanCategories: CreditPlanCategories,
      contractType: null,
      redemPackages: [],
      creditPlanCategory: CreditPlanCategories.EXPIRING,
      creditAmount: 0,
      paymentAmount: 0,
      pricePerCredit: 0,
      isCustomContract: false
    }
  },
  watch: {
    contractType(newType) {
      const redemPackage = this.redemPackages.find(p => p.name === newType);
      if (redemPackage) {
        this.creditAmount = redemPackage.numberOfCredits;
        this.pricePerCredit = redemPackage.pricePerCredit;
        this.paymentAmount = redemPackage.numberOfCredits * redemPackage.pricePerCredit;
      }
      // Determine if the new contract type is custom
      this.isCustomContract = newType === contractTypesEnums.CUSTOM;
      
      // Reset creditPlanCategory if not custom contract and current selection is not allowed
      if (!this.isCustomContract && 
          (this.creditPlanCategory === CreditPlanCategories.NON_EXPIRING || 
           this.creditPlanCategory === CreditPlanCategories.PAY_AS_USAGE)) {
        this.creditPlanCategory = CreditPlanCategories.EXPIRING;
      }
    }
  },
  validations: {
    creditPlanCategory: { required },
    creditAmount: {
      required: requiredIf(
        function () {
          return this.creditPlanCategory !== CreditPlanCategories.PAY_AS_USAGE
        }
      ),
      minValue: minValue(1)
    },
    pricePerCredit: { required },
    paymentAmount: { minValue: minValue(0) }
  },
  methods: {
    validate() {
      this.$v.$touch();
      let isValid = !this.$v.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    }
  }
}
</script>
<template>
  <div>
    <!-- Credti Plan type -->
    <div class="form-group row mb-4">
      <div class="col-md-12">
        <b-form-radio-group
          id="creditPlanCategory"
          v-model="creditPlanCategory"
        >
          <b-form-radio
            class="mt-2"
            :value="CreditPlanCategories.EXPIRING"
          >
            {{ $t('adminComponents.creditPlanInfoForm.creditPlanCategory.expiring') }}
          </b-form-radio>

          <b-form-radio
            class="mt-2"
            :value="CreditPlanCategories.NON_EXPIRING"
            :disabled="!isCustomContract"
          >
            {{ $t('adminComponents.creditPlanInfoForm.creditPlanCategory.nonExpiring') }}
          </b-form-radio>

          <b-form-radio
            class="mt-2"
            :value="CreditPlanCategories.PAY_AS_USAGE"
            :disabled="!isCustomContract"
          >
            {{ $t('adminComponents.creditPlanInfoForm.creditPlanCategory.payAsUsage') }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <!-- Credits and price per credit -->
    <div class="form-group row mb-4 mt-4">
      <div class="col-md-6" v-if="creditPlanCategory !== CreditPlanCategories.PAY_AS_USAGE">
        <label for="creditAmount">
          {{ $t('adminComponents.creditPlanInfoForm.credits.label') }}
          <span class="text-danger">*</span>
        </label>
        <b-form-input
          id="creditAmount"
          v-model="creditAmount"
          type="number"
          min="0"
          :placeholder="$t('adminComponents.creditPlanInfoForm.credits.placeholder')"
          :class="{
            'is-invalid': $v.creditAmount.$error
          }"
        />
        <div v-if="$v.creditAmount.$error" class="invalid-feedback">
          <span v-if="!$v.creditAmount.required"> {{ $t('adminComponents.creditPlanInfoForm.credits.requiredError') }} </span>
          <span v-if="!$v.creditAmount.minValue"> {{ $t('adminComponents.creditPlanInfoForm.credits.minValueError') }} </span>
        </div>
      </div>
      <div class="col-md-6">
        <label for="pricePerCredit">
          {{ $t('adminComponents.creditPlanInfoForm.pricePerCredit.label') }}
        </label>
        <b-form-input
          id="pricePerCredit"
          v-model="pricePerCredit"
          type="number"
          min="0"
          :placeholder="$t('adminComponents.creditPlanInfoForm.pricePerCredit.placeholder')"
        />
      </div>
    </div>

    <!-- Payment in Euros -->
    <div class="form-group row mb-3" v-if="creditPlanCategory !== CreditPlanCategories.PAY_AS_USAGE">
      <div class="col-md-6">
        <label for="paymentAmount">
          {{ $t('adminComponents.creditPlanInfoForm.payment.label') }}
        </label>
        <b-form-input
          id="paymentAmount"
          v-model="paymentAmount"
          type="number"
          min="0"
          :placeholder="$t('adminComponents.creditPlanInfoForm.payment.placeholder')"
        />
      </div>
    </div>
  </div>
</template>
const ContractTypes = {
  STARTER: "STARTER",
  PROFESSIONAL: "PROFESSIONAL",
  ENTERPRISE: "ENTERPRISE",
  CUSTOM: "CUSTOM",
  TEST: "TEST"
};

export default ContractTypes;


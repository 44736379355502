<script>
import OrganizationCreditPlanCard from "./organization-credit-plans-card.vue"
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    organization: Object
  },
  components: {
    OrganizationCreditPlanCard
  },
  data() {
    return {
      /***** Data variables *****/
      numberOfComponentsShouldGetLoaded: 1,
      numberOfComponentsGetLoaded: 0,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
    }
  },
  created() {
    EventBus.$on("creditPlansComponetGetLoaded", async () => {
      this.numberOfComponentsGetLoaded += 1

      if(this.numberOfComponentsShouldGetLoaded === this.numberOfComponentsGetLoaded) {
        this.isComponentLoading = false;
      }
    });
  }
}
</script>

<template>
  <div>
    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Data -->
    <div v-show="!isComponentLoading && !isFetchingDataError" class="row mt-3 animate__animated animate__fadeInUp animate__slow">
      <div class="col-md-12">
        <OrganizationCreditPlanCard :organization="organization" />
      </div>
    </div>
  </div>
</template>
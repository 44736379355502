<script>
import { getErrorMessageByErrorCode, getDateAccoringTolanguage } from "@/shared/util/helpers.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import ContractService from "@/api/services/contract.service.js";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    contractToDelete: Object
  },
  data() {
    return {
      isDeleteContractSuccess: false,
      isDeleteContractError: false,
      deleteContractErrorMessage: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    async clickDelete() {
      try {
        let response = await ContractService.deleteContract(this.contractToDelete.id);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isDeleteContractSuccess = true;
          setTimeout(() => {
            this.isDeleteContractSuccess = false;
            this.$emit("close");
            EventBus.$emit("refreshOrganizationContractsWidgets");
          }, 2000);
        }
      } catch(error) {
        this.isDeleteContractError = true;
        this.deleteContractErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row mb-4">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.deleteContractOverlay.title")}} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Error Message -->
    <b-alert
      v-model="isDeleteContractError"
      class="mt-3 mb-4"
      variant="danger"
      dismissible
      >{{ deleteContractErrorMessage }}</b-alert
    >

    <!-- Success Message -->
    <b-alert
      v-model="isDeleteContractSuccess"
      class="mt-3 mb-4"
      variant="success"
    >
      <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.deleteContractOverlay.successMessage")}}
    </b-alert>

    <!-- Contract -->
    <div class="row">
      <div class="col-md-6">
        {{$t("adminComponents.editContractOverlay.type")}}: <span class="badge badge-soft-primary font-size-12 ml-1"> {{contractToDelete.type}} </span>
      </div>
      <div class="col-md-6">
        {{$t("adminComponents.editContractOverlay.reference")}}: {{contractToDelete.name}}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        {{$t("adminComponents.editContractOverlay.createdAt")}}: {{ formatDate(contractToDelete.createdAt)}}
      </div>
      <div class="col-md-6">
        {{$t("adminComponents.editContractOverlay.createdBy")}}: {{contractToDelete.createdBy}}
      </div>
    </div>

    <hr/>

    <!-- Text -->
    <div class="row mt-4">
      <div class="col-md-12">
        {{$t("adminComponents.deleteContractOverlay.description")}}
      </div>
    </div>

    <!-- Actions -->
    <div class="row mt-4 mb-3">
      <div class="col-md-12">
        <b-button variant="danger" @click="clickDelete">
          {{$t("adminComponents.deleteContractOverlay.deleteButton")}}
        </b-button>
        <b-button variant="light" @click="clickClose" class="ml-2">
          {{$t("adminComponents.deleteContractOverlay.cancelButton")}}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
import { render, staticRenderFns } from "./contract-detail-card.vue?vue&type=template&id=5e03380e&scoped=true"
import script from "./contract-detail-card.vue?vue&type=script&lang=js"
export * from "./contract-detail-card.vue?vue&type=script&lang=js"
import style0 from "./contract-detail-card.vue?vue&type=style&index=0&id=5e03380e&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e03380e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e03380e')) {
      api.createRecord('5e03380e', component.options)
    } else {
      api.reload('5e03380e', component.options)
    }
    module.hot.accept("./contract-detail-card.vue?vue&type=template&id=5e03380e&scoped=true", function () {
      api.rerender('5e03380e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/shared/components/administrational-components/widgets/contract-detail-card.vue"
export default component.exports
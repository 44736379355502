var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { height: "610px" } },
    [
      _vm.isComponentLoading
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row pt-5" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 text-center p-5" },
                  [
                    _c("b-spinner", {
                      staticClass: "m-2",
                      staticStyle: { width: "5rem", height: "5rem" },
                      attrs: { variant: "mid", role: "status" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isComponentLoading && this.contractDetails
        ? _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-text" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "medium",
                        "font-weight": "600",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.contractDetails.type) + " ")]
                  ),
                  _vm.contractDetails.status !==
                    _vm.contractStatusEnum.EXPIRED && _vm.isSuperAdminComponent
                    ? _c("span", [
                        _c("i", {
                          staticClass: "bx bxs-pencil ml-2",
                          staticStyle: {
                            "font-size": "medium",
                            cursor: "pointer",
                          },
                          on: { click: _vm.clickEditContract },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.startDate"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.formatDate(_vm.contractDetails.startDate)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.endDate"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.formatDate(_vm.contractDetails.endDate)) +
                      " "
                  ),
                ]),
                _vm.contractDetails.status === _vm.contractStatusEnum.ACTIVE
                  ? _c("div", { staticClass: "col-md-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.remainingDays"
                            )
                          ) +
                          ": " +
                          _vm._s(
                            _vm.getNumberOfDays(_vm.contractDetails.endDate)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.contractDetails.status !== _vm.contractStatusEnum.ACTIVE
                  ? _c("div", { staticClass: "col-md-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.duration"
                            )
                          ) +
                          ": " +
                          _vm._s(
                            _vm.getNumberOfDaysBetweenTwoDates(
                              _vm.contractDetails.startDate,
                              _vm.contractDetails.endDate
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractsWidget.contractDetails.days"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.renewsItSelft"
                        )
                      ) +
                      ": "
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "badge badge-soft-secondary font-size-12 ml-1",
                      class: {
                        "badge-soft-success":
                          _vm.contractDetails.autoRenew === true,
                        "badge-soft-danger":
                          _vm.contractDetails.autoRenew === false,
                      },
                    },
                    [
                      _vm.contractDetails.autoRenew
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("general.yes")) + " "),
                          ])
                        : _c("span", [
                            _vm._v(" " + _vm._s(_vm.$t("general.no")) + " "),
                          ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.numberOfDataImports"
                        )
                      ) +
                      ": " +
                      _vm._s(
                        _vm.formatNumber(_vm.contractDetails.numberOfDatafiles)
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.numberOfDataRecords"
                        )
                      ) +
                      ": " +
                      _vm._s(
                        _vm.formatNumber(
                          _vm.contractDetails.numberOfDataRecords
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.createdAt"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.formatDate(_vm.contractDetails.createdAt)) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.createdBy"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.contractDetails.createdBy) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.contractsWidget.contractDetails.reference"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.contractDetails.name) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "row mt-5" }, [
                _vm.contractDetails.primaryCreditPlan
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "custom-credit-plan-card" },
                        [
                          _vm.contractDetails.primaryCreditPlan.isPayPerUsage ||
                          (!_vm.contractDetails.primaryCreditPlan
                            .isPayPerUsage &&
                            _vm.contractDetails.primaryCreditPlan.credits
                              .balance > 0)
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "light" },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle bx-flashing text-success",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "normal",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.contractsWidget.contractDetails.primaryCreditPlan"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(
                            " - " +
                              _vm._s(
                                _vm.contractDetails.primaryCreditPlan.name
                              ) +
                              " "
                          ),
                          !_vm.contractDetails.primaryCreditPlan.isPayPerUsage
                            ? _c("div", [
                                _c("div", { staticClass: "row mt-1" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-center" },
                                    [
                                      _c("apexchart", {
                                        ref: "CreditBalanceChart",
                                        staticClass:
                                          "apex-charts apexChartData",
                                        attrs: {
                                          type: "radialBar",
                                          height: "250",
                                          dir: "ltr",
                                          series: this.creditBalancePercentage,
                                          options:
                                            _vm.creditBalanceChartOptions
                                              .chartOptions,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row mt-2 mb-1" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-6 text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "adminComponents.contractsWidget.contractDetails.remaning"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "normal",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatCredits(
                                                  _vm.contractDetails
                                                    .primaryCreditPlan.credits
                                                    .balance
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-6 text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "adminComponents.contractsWidget.contractDetails.received"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "normal",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatCredits(
                                                  _vm.contractDetails
                                                    .primaryCreditPlan.credits
                                                    .received
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.contractDetails.primaryCreditPlan.isPayPerUsage
                            ? _c("div", [
                                _c("div", { staticClass: "row mt-5" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.usage"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "xx-large",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatCredits(
                                                  _vm.contractDetails
                                                    .primaryCreditPlan.credits
                                                    .received
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("hr"),
                                _c("div", { staticClass: "row mt-4" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-center" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.title"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "x-large",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " € " +
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  _vm.contractDetails
                                                    .primaryCreditPlan.credits
                                                    .received *
                                                    _vm.contractDetails
                                                      .primaryCreditPlan
                                                      .pricePerCredit
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("small", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.description"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.contractDetails.backupCreditPlan
                  ? _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "custom-credit-plan-card" },
                        [
                          !_vm.contractDetails.primaryCreditPlan
                            .isPayPerUsage &&
                          _vm.contractDetails.primaryCreditPlan.credits
                            .balance === 0
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "mr-2",
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { variant: "light" },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bxs-circle bx-flashing text-success",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "normal",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.contractsWidget.contractDetails.backupCreditPlan.title"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(
                            " - " +
                              _vm._s(
                                _vm.contractDetails.backupCreditPlan.name
                              ) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right.v-light",
                                  modifiers: {
                                    hover: true,
                                    right: true,
                                    "v-light": true,
                                  },
                                },
                              ],
                              attrs: {
                                title: _vm.$i18n.t(
                                  "adminComponents.contractsWidget.contractDetails.backupCreditPlan.description"
                                ),
                              },
                            },
                            [_c("i", { staticClass: "bx bx-info-circle" })]
                          ),
                          _c("div", { staticClass: "row mt-5" }, [
                            _c("div", { staticClass: "col-12 text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.usage"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "xx-large",
                                    "font-weight": "600",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatCredits(
                                          _vm.contractDetails.backupCreditPlan
                                            .credits.received
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c("hr"),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c("div", { staticClass: "col-12 text-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.title"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "x-large",
                                    "font-weight": "600",
                                  },
                                },
                                [
                                  _vm._v(
                                    " € " +
                                      _vm._s(
                                        _vm.formatCurrency(
                                          _vm.contractDetails.backupCreditPlan
                                            .credits.received *
                                            _vm.contractDetails.backupCreditPlan
                                              .pricePerCredit
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("small", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.description"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.isDisplayEditContractOverlay === true
        ? _c("EditContractOverlay", {
            attrs: {
              visible: _vm.isDisplayEditContractOverlay,
              contractToEdit: _vm.contractDetails,
              organizationActiveContracts: _vm.organizationActiveContract,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayEditContractOverlay = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayDeleteContractOverlay === true
        ? _c("DeleteContractOverlay", {
            attrs: {
              visible: _vm.isDisplayDeleteContractOverlay,
              contractToDelete: _vm.contractToDelete,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayDeleteContractOverlay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-md-10" }, [
          _c("span", { staticClass: "mr-2" }, [
            _vm.organization && _vm.organization.image
              ? _c("img", {
                  staticClass: "header-profile-user",
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: { src: _vm.organization.image },
                })
              : _c("img", {
                  staticClass: "header-profile-user",
                  staticStyle: { width: "50px", height: "50px" },
                  attrs: {
                    src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                  },
                }),
          ]),
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { "font-size": "medium", "font-weight": "600" },
            },
            [_vm._v(" " + _vm._s(_vm.organization.name) + " ")]
          ),
          _c("span", [
            _c("i", {
              staticClass: "bx bxs-pencil",
              staticStyle: { "font-size": "medium", cursor: "pointer" },
              on: { click: _vm.clickEditOrganization },
            }),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "col-md-2 d-flex align-items-center text-center justify-content-between",
          },
          [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.organizationDetailsHeader.numberOfDataImports"
                    )
                  ) +
                  " "
              ),
              _c(
                "h5",
                { staticStyle: { "font-weight": "600", "margin-bottom": "0" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.formatNumbers(
                        _vm.organizationStats.numberOfDataImports
                      )
                    )
                  ),
                ]
              ),
            ]),
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.organizationDetailsHeader.numberOfDataRecords"
                    )
                  ) +
                  " "
              ),
              _c(
                "h5",
                { staticStyle: { "font-weight": "600", "margin-bottom": "0" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNumbers(
                          _vm.organizationStats.numberOfDataRecords
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _vm.isSuperAdminComponent
              ? _c(
                  "b-dropdown",
                  {
                    attrs: { size: "sm", variant: "light", dropright: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "bx bx-dots-vertical-rounded",
                                staticStyle: { "font-size": "large" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      488134630
                    ),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickAssignNewContract()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bx bx-plus-circle mr-1" }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.organizationDetailsHeader.dropdown.assignNewContract"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.isDisplayEditOrganizationOverlay === true
        ? _c("EditOrganizationOverlay", {
            attrs: {
              visible: _vm.isDisplayEditOrganizationOverlay,
              organization: _vm.organization,
              candidatesForOrganizationAdmin: _vm.organizationAdmins,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayEditOrganizationOverlay = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayAssignNewContractOverlay === true
        ? _c("AssignUpcomingContractOverlay", {
            attrs: {
              visible: _vm.isDisplayAssignNewContractOverlay,
              organization: _vm.organization,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayAssignNewContractOverlay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
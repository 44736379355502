import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class CreditPlanService {
  /****** Get Company Credit Summary *****/
  async getCompanyCreditSummary(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyCreditSummary($companyId: ID!){
            getCompanyCreditSummary(companyId: $companyId) {
              activeCreditPlanBalance
              activeCreditPlanReceived
              activePayAsUsageCreditPlanReceived
              totalBalance
              totalReceived
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyCreditSummary && !response?.data?.errors) {
      return response?.data?.data?.getCompanyCreditSummary;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }

  }

  /****** Get Company Credit Plans *****/
  async getCompanyCreditPlans(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyCreditPlans($companyId: ID!){
            getCompanyCreditPlans(companyId: $companyId) {
              id
              name
              createdAt
              createdBy
              type
              credits {
                balance
                received
              }
              isExpiring
              isPayPerUsage
              pricePerCredit
              startDate
              expirationDate
              status
              company
              invoice {
                number
                ulr
              }
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyCreditPlans && !response?.data?.errors) {
      return response?.data?.data?.getCompanyCreditPlans;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }

  }

  /****** Get Company Credit Plans by type *****/
  async getCompanyCreditPlansByType(companyId, type) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyCreditPlansByType($companyId: ID!, $type: CreditPlanTypes!){
            getCompanyCreditPlansByType(companyId: $companyId, type: $type) {
              id
              name
              createdAt
              createdBy
              type
              credits {
                balance
                received
              }
              isExpiring
              isPayPerUsage
              pricePerCredit
              startDate
              expirationDate
              status
              company
              invoice {
                number
                ulr
              }
            }
          }
        `,
        variables: {
          companyId: companyId,
          type: type
        }
      }
    });

    if (response?.data?.data?.getCompanyCreditPlansByType && !response?.data?.errors) {
      return response?.data?.data?.getCompanyCreditPlansByType;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }

  }
}

export default new CreditPlanService();

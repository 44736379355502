7<script>
import EditOrganizationOverlay from '../overlays/edit-organization-overlay.vue';
import { getNumbersAccoringTolanguage } from "@/shared/util/helpers.js";
import AssignUpcomingContractOverlay from '../overlays/assign-upcoming-contract-overlay.vue';

export default {
  props: {
    organization: Object,
    organizationAdmins: Array,
    organizationStats: Object,
    isSuperAdminComponent: Boolean
  },
  components: {
    EditOrganizationOverlay,
    AssignUpcomingContractOverlay
  },
  data() {
    return {
      /***** Data variables *****/
      dataFetchingnErrorMessage: null,
      /****** Operational variable ******/
      isFetchingDataError: false,
      isDisplayEditOrganizationOverlay: false,
      isDisplayAssignNewContractOverlay: false
    }
  },
  mounted() {
  },
  methods: {
    clickEditOrganization() {
      this.isDisplayEditOrganizationOverlay = true
    },
    formatNumbers(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getNumbersAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    clickAssignNewContract() {
      this.isDisplayAssignNewContractOverlay = true;
    }
  }
}
</script>

<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-10">
        <span class="mr-2">
          <img
            v-if="organization && organization.image"
            :src="organization.image"
            class="header-profile-user"
            style="width: 50px; height: 50px"
          />
          <img
            v-else
            src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
            class="header-profile-user"
            style="width: 50px; height: 50px"
          />
        </span>
        <span class="mr-2" style="font-size: medium; font-weight: 600"> {{organization.name}} </span>
        <span> <i class='bx bxs-pencil' style="font-size: medium; cursor: pointer;" @click="clickEditOrganization"></i> </span>
      </div>

      <div class="col-md-2 d-flex align-items-center text-center justify-content-between">
        <div>
          {{$t("adminComponents.organizationDetailsHeader.numberOfDataImports")}}
          <h5 style="font-weight: 600; margin-bottom: 0;">{{ formatNumbers(organizationStats.numberOfDataImports) }}</h5>
        </div>

        <div>
          {{$t("adminComponents.organizationDetailsHeader.numberOfDataRecords")}}
          <h5 style="font-weight: 600; margin-bottom: 0;"> {{ formatNumbers(organizationStats.numberOfDataRecords) }} </h5>
        </div>

        <b-dropdown
          size="sm"
          variant="light"
          dropright
          v-if="isSuperAdminComponent"
        >
          <template v-slot:button-content>
            <i class="bx bx-dots-vertical-rounded" style="font-size: large;"></i>
          </template>
          <!-- Assign new contract -->
          <b-dropdown-item @click="clickAssignNewContract()">
            <i class="bx bx-plus-circle mr-1"></i>
            {{ $t("adminComponents.organizationDetailsHeader.dropdown.assignNewContract") }}
          </b-dropdown-item>

        </b-dropdown>
      </div>
    </div>

    <!-- Edit Organization Overlay -->
    <EditOrganizationOverlay
      v-if="isDisplayEditOrganizationOverlay === true"
      :visible="isDisplayEditOrganizationOverlay"
      @close="isDisplayEditOrganizationOverlay = false"
      :organization="organization"
      :candidatesForOrganizationAdmin="organizationAdmins"
    />

    <!-- Assign New Contract Overlay -->
    <AssignUpcomingContractOverlay
      v-if="isDisplayAssignNewContractOverlay === true"
      :visible="isDisplayAssignNewContractOverlay"
      @close="isDisplayAssignNewContractOverlay = false"
      :organization="organization"
    />
  </div>
</template>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isComponentLoading
        ? _c("div", { staticClass: "row pt-5" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isComponentLoading &&
      (!_vm.organizations || _vm.organizations.length === 0)
        ? _c("div", { staticClass: "row pt-5" }, [_vm._m(0)])
        : _vm._e(),
      !_vm.isComponentLoading &&
      _vm.organizations &&
      _vm.organizations.length > 0
        ? _c("div", { staticClass: "mt-4" }, [
            _c("div", { staticClass: "row mb-4" }, [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("b-form-input", {
                    attrs: { type: "search", placeholder: "Search" },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "table-responsive mb-0" },
              [
                _c("b-table", {
                  attrs: {
                    items: _vm.dataForTable,
                    fields: _vm.fieldsForTable,
                    responsive: "sm",
                    "per-page": _vm.perPage,
                    "current-page": _vm.currentPage,
                    bordered: true,
                    filter: _vm.filter,
                    "filter-included-fields": ["organization"],
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(createdDate)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.formatDate(data.value)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(metaData)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "row align-items-center" },
                              [
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c("img", {
                                    staticClass: "rounded-circle mr-2",
                                    staticStyle: { height: "30px" },
                                    attrs: { src: data.value.image },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "600" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.value.name) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(owner)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row align-items-center justify-content-between",
                              },
                              [
                                _c("div", { staticClass: "col-1" }, [
                                  _c("img", {
                                    staticClass: "rounded-circle",
                                    staticStyle: { height: "30px" },
                                    attrs: { src: data.value.image },
                                  }),
                                ]),
                                _c("div", { staticClass: "col-11" }, [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "600" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.value.name) + " "
                                      ),
                                    ]
                                  ),
                                  _c("small", [
                                    _vm._v(
                                      " " + _vm._s(data.value.email) + " "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(action)",
                        fn: function (scope) {
                          return [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickActivateOrganization(
                                      scope.item
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "bx bx-check mr-2",
                                  staticStyle: { "font-size": "medium" },
                                }),
                                _vm._v(" Activate "),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(delete)",
                        fn: function (scope) {
                          return [
                            _c("i", {
                              staticClass: "bx bx-trash",
                              staticStyle: {
                                "font-size": "15px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickDeleteOrganization(scope.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3518846415
                  ),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right",
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination pagination-rounded mb-0" },
                      [
                        _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.rows,
                            "per-page": _vm.perPage,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.showOrganizationActivationModal
        ? _c("ActivateOrganizationModal", {
            attrs: {
              visible: _vm.showOrganizationActivationModal,
              organization: _vm.organizationToInitialActivation,
            },
            on: {
              close: function ($event) {
                return _vm.closeActivateOrganizationModal()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 text-center p-5" }, [
      _c("img", {
        staticStyle: { height: "60px" },
        attrs: { src: require("@/assets/images/information.png") },
      }),
      _c("div", { staticClass: "mt-3" }, [_vm._v(" No New Registrations ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isComponentLoading
      ? _c(
          "div",
          { staticClass: "animate__animated animate__fadeInUp animate__slow" },
          [
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("OrganizationContractsCard", {
                    attrs: { organization: _vm.organization },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-md-8" },
                [
                  _c("ContractDetailCard", {
                    attrs: {
                      contract: this.selectedContract,
                      organizationActiveContract:
                        this.organizationActiveContract,
                      isSuperAdminComponent: this.isSuperAdminComponent,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    !_vm.isComponentLoading && _vm.isFetchingDataError
      ? _c("div", { staticClass: "row pt-5" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _vm._v(" " + _vm._s(this.dataFetchingnErrorMessage) + " "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("label", { attrs: { for: "contractType" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.contractInfoForm.contractType.label")
                ) +
                " "
            ),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
          _c("multiselect", {
            class: { "is-invalid": _vm.$v.selectedContractType.$error },
            attrs: {
              id: "contractType",
              options: _vm.contractTypes,
              placeholder: _vm.$t(
                "adminComponents.contractInfoForm.contractType.placeholder"
              ),
            },
            on: { select: _vm.selectContractType },
            model: {
              value: _vm.selectedContractType,
              callback: function ($$v) {
                _vm.selectedContractType = $$v
              },
              expression: "selectedContractType",
            },
          }),
          _vm.$v.selectedContractType.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                !_vm.$v.selectedContractType.required
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractInfoForm.contractType.requiredError"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "form-group row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("label", { attrs: { for: "contractStartDate" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.contractInfoForm.startDate.label")
                ) +
                " "
            ),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
          _c("date-picker", {
            class: {
              "is-invalid": _vm.$v.contractStartDate.$error,
            },
            attrs: {
              id: "contractStartDate",
              placeholder: _vm.$t(
                "adminComponents.contractInfoForm.startDate.placeholder"
              ),
              format: "DD.MM.YYYY",
              disabled: !_vm.currentActiveContract,
              "disabled-date": _vm.disabledStartDates,
            },
            model: {
              value: _vm.contractStartDate,
              callback: function ($$v) {
                _vm.contractStartDate = $$v
              },
              expression: "contractStartDate",
            },
          }),
          _vm.$v.contractStartDate.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                !_vm.$v.contractStartDate.required
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractInfoForm.startDate.requiredError"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("label", { attrs: { for: "contractEndDate" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.contractInfoForm.endDate.label")
                ) +
                " "
            ),
            _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
          ]),
          _c("date-picker", {
            class: {
              "is-invalid": _vm.$v.contractEndDate.$error,
            },
            attrs: {
              id: "contractEndDate",
              placeholder: _vm.$t(
                "adminComponents.contractInfoForm.endDate.placeholder"
              ),
              format: "DD.MM.YYYY",
              disabled: !_vm.contractStartDate,
              "disabled-date": _vm.disabledEndDates,
            },
            model: {
              value: _vm.contractEndDate,
              callback: function ($$v) {
                _vm.contractEndDate = $$v
              },
              expression: "contractEndDate",
            },
          }),
          _vm.$v.contractEndDate.$error
            ? _c("div", { staticClass: "invalid-feedback" }, [
                !_vm.$v.contractEndDate.required
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.contractInfoForm.endDate.requiredError"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm.contractEndDate && _vm.contractStartDate
      ? _c("div", { staticClass: "row mb-4 col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.contractInfoForm.days1")) +
              " " +
              _vm._s(
                _vm.getNumberOfDaysBetweenTwoDates(
                  _vm.contractStartDate,
                  _vm.contractEndDate
                )
              ) +
              " " +
              _vm._s(_vm.$t("adminComponents.contractInfoForm.days2")) +
              " "
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "form-group row mb-4 col-md-12" },
      [
        _c(
          "b-form-checkbox",
          {
            attrs: {
              label: "Auto Renew",
              name: "autoRenew",
              id: "autoRenew",
              disabled: _vm.disableIsAutoRenew,
            },
            model: {
              value: _vm.isAutoRenew,
              callback: function ($$v) {
                _vm.isAutoRenew = $$v
              },
              expression: "isAutoRenew",
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("adminComponents.contractInfoForm.autoRenew")) +
                " "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Step1 from "@/shared/components/administrational-components/widgets/contract-info-form.vue";
import Step2 from "@/shared/components/administrational-components/widgets/credit-plan-info-form.vue";
import Step3 from "@/shared/components/administrational-components/widgets/business-info-form.vue";
import CompanyService from "@/api/services/company.service.js";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import CreditPlanCategories from "@/shared/enums/creditPlanCategories.js"

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    organization: Object
  },
  components: {
    FormWizard,
    TabContent,
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      /****** Data Variables *****/
      formData: {},
      /****** Operational Variables ******/
      organizationActivationErrorMessage: null,
      isOrganizationActivationError: false,
      organizationActivationSuccess: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    mergePartialModels(model, isValid){
      if(isValid){
      // merging each step model into the final model
       this.formData = { ...this.formData, ...model };
       this.$refs.step2.contractType = this.formData.selectedContractType;
       this.$refs.step2.redemPackages = this.formData.redemPackages;
      }
    },    
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    async onComplete() {
      try {
        const organizationActivationDTO = {
          companyId: this.organization.id,
          contract: {
            contractType: this.formData.selectedContractType,
            startDate: this.formData.contractStartDate,
            endDate: this.formData.contractEndDate,
            autoRenew: this.formData.isAutoRenew,
          },
          creditPlan: {
            creditPlanCategory: this.formData.creditPlanCategory,
            creditAmount: this.formData.creditPlanCategory === CreditPlanCategories.PAY_AS_USAGE ? 0 : Number(this.formData.creditAmount),
            pricePerCredit: Number(this.formData.pricePerCredit),
            paymentAmount: this.formData.creditPlanCategory === CreditPlanCategories.PAY_AS_USAGE ? 0 : Number(this.formData.paymentAmount)
          },
          accountManager: this.formData.accountManager,
          notes: this.formData.notes
        }

        await CompanyService.activateOrganization(organizationActivationDTO);
        this.organizationActivationSuccess = true;
      } catch(error) {
        this.isOrganizationActivationError = true;
        this.organizationActivationErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div>

      <!-- Title -->
      <div class="row mb-4">
        <div class="col-10">
          <h5> {{ $t("adminComponents.activateOrganization.title") }}</h5>
        </div>
        <div class="col-md-2 text-right">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
      </div>
      
      <!-- Organization -->
      <div class="row mb-3 align-items-center">
        <div class="col-md-5 text-right">
          <img
            :src="organization.metaData.image"
            style="width: 50px; height: 50px"
            alt="Organization Logo"
          />
        </div>
        <div class="col-md-7">
          <div style="font-size: 15px;font-weight: 600"> {{ organization.metaData.name }} </div>
          <small> {{ organization.owner.email }} </small>
        </div>
      </div>

      <!-- Form -->
      <div class="row justify-content-center">

        <b-alert
          v-model="isOrganizationActivationError"
          class="mt-3"
          variant="danger"
          dismissible
          >{{ organizationActivationErrorMessage }}</b-alert
        >
        
        <div class="col-12 mt-3 mb-4" v-if="!organizationActivationSuccess">
          <form-wizard
            @on-complete="onComplete"
            color="#005181"
            back-button-text="Previous"
            finish-button-text="Activate"
            class="custom-wizard"
            shape="circle"
            step-size="xs"
          >
            <tab-content :title="$t('adminComponents.activateOrganization.step1')" :before-change="()=>validateStep('step1')">
              <step1 ref="step1" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content :title="$t('adminComponents.activateOrganization.step2')" :before-change="()=>validateStep('step2')">
              <step2 ref="step2" @on-validate="mergePartialModels"/>
            </tab-content>
            <tab-content :title="$t('adminComponents.activateOrganization.step3')" :before-change="()=>validateStep('step3')">
              <step3 ref="step3" @on-validate="mergePartialModels"/>
            </tab-content>
            <b-button slot="prev" variant="light" class="mr-2">{{ $t("adminComponents.activateOrganization.actions.previous") }}</b-button>
            <b-button slot="next" variant="primary">{{ $t("adminComponents.activateOrganization.actions.next") }}</b-button>
            <b-button slot="finish" variant="success">{{ $t("adminComponents.activateOrganization.actions.activate") }}</b-button>        
          </form-wizard>
        </div>

        <!--  Success Activation -->
        <div class="col-10 mt-2 mb-2" v-else>
          <hr class="mb-4" />
          <div class="text-center">
            <h5 class="mb-3">{{ $t("adminComponents.activateOrganization.success.title") }}</h5>
            <p>{{ $t("adminComponents.activateOrganization.success.text") }}</p>
            <b-button @click="clickClose" variant="primary">{{ $t("adminComponents.activateOrganization.actions.close") }}</b-button>
          </div>
        </div>
      </div>

    </div>
  </b-modal>
</template>


<style scoped>
.custom-wizard ::v-deep .wizard-card-footer {
  display: flex;
  justify-content: flex-start;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row d-flex align-items-center" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.editContractOverlay.title")) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _c("div", { staticClass: "mt-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("adminComponents.editContractOverlay.type")) +
                ": "
            ),
            _c(
              "span",
              { staticClass: "badge badge-soft-primary font-size-12 ml-1" },
              [_vm._v(" " + _vm._s(_vm.contractToEdit.type) + " ")]
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.editContractOverlay.reference")
                ) +
                ": " +
                _vm._s(_vm.contractToEdit.name) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.editContractOverlay.createdAt")
                ) +
                ": " +
                _vm._s(_vm.formatDate(_vm.contractToEdit.createdAt)) +
                " "
            ),
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("adminComponents.editContractOverlay.createdBy")
                ) +
                ": " +
                _vm._s(_vm.contractToEdit.createdBy) +
                " "
            ),
          ]),
        ]),
        _c("hr"),
      ]),
      _c(
        "div",
        [
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.isEditContractError,
                callback: function ($$v) {
                  _vm.isEditContractError = $$v
                },
                expression: "isEditContractError",
              },
            },
            [_vm._v(_vm._s(_vm.editContractErrorMessage))]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isEditContractSuccess,
                callback: function ($$v) {
                  _vm.isEditContractSuccess = $$v
                },
                expression: "isEditContractSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.editContractOverlay.updateSuccess")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row form-group mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("label", { attrs: { for: "contractStartDate" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.editContractOverlay.startDate.label"
                    )
                  ) +
                  " "
              ),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            ]),
            _c("date-picker", {
              class: {
                "is-invalid": _vm.$v.contractStartDate.$error,
              },
              attrs: {
                id: "contractStartDate",
                placeholder: _vm.$t(
                  "adminComponents.editContractOverlay.startDate.placeholder"
                ),
                format: "DD.MM.YYYY",
                "disabled-date": _vm.disabledStartDates,
                disabled:
                  _vm.contractToEdit.status === _vm.contractStatusEnum.ACTIVE,
              },
              on: {
                input: function ($event) {
                  return _vm.selectNewStartDate($event)
                },
              },
              model: {
                value: _vm.contractStartDate,
                callback: function ($$v) {
                  _vm.contractStartDate = $$v
                },
                expression: "contractStartDate",
              },
            }),
            _vm.$v.contractStartDate.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  !_vm.$v.contractStartDate.required
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.editContractOverlay.startDate.required"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _c("label", { attrs: { for: "contractEndDate" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.editContractOverlay.endDate.label")
                  ) +
                  " "
              ),
              _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
            ]),
            _c("date-picker", {
              class: {
                "is-invalid": _vm.$v.contractEndDate.$error,
              },
              attrs: {
                id: "contractEndDate",
                placeholder: _vm.$t(
                  "adminComponents.editContractOverlay.endDate.placeholder"
                ),
                format: "DD.MM.YYYY",
                disabled: !_vm.contractStartDate,
                "disabled-date": _vm.disabledEndDates,
              },
              model: {
                value: _vm.contractEndDate,
                callback: function ($$v) {
                  _vm.contractEndDate = $$v
                },
                expression: "contractEndDate",
              },
            }),
            _vm.$v.contractEndDate.$error
              ? _c("div", { staticClass: "invalid-feedback" }, [
                  !_vm.$v.contractEndDate.required
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.editContractOverlay.endDate.required"
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.contractEndDate && _vm.contractStartDate
        ? _c("div", { staticClass: "row mb-4 col-md-12" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("adminComponents.editContractOverlay.days1")) +
                " " +
                _vm._s(
                  _vm.getNumberOfDaysBetweenTwoDates(
                    _vm.contractStartDate,
                    _vm.contractEndDate
                  )
                ) +
                " " +
                _vm._s(_vm.$t("adminComponents.editContractOverlay.days2")) +
                " "
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-group row mt-4 col-md-12" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: {
                label: "Auto Renew",
                name: "autoRenew",
                id: "autoRenew",
              },
              model: {
                value: _vm.isAutoRenew,
                callback: function ($$v) {
                  _vm.isAutoRenew = $$v
                },
                expression: "isAutoRenew",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.editContractOverlay.autoRenew")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              { attrs: { variant: "primary" }, on: { click: _vm.clickSave } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.editContractOverlay.saveButton")
                    ) +
                    " "
                ),
              ]
            ),
            _vm.contractToEdit.status === _vm.contractStatusEnum.UPCOMING
              ? _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "light" },
                    on: { click: _vm.clickDeleteContract },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "adminComponents.editContractOverlay.deleteButton"
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
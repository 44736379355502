var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.deleteContractOverlay.title")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _c(
        "b-alert",
        {
          staticClass: "mt-3 mb-4",
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.isDeleteContractError,
            callback: function ($$v) {
              _vm.isDeleteContractError = $$v
            },
            expression: "isDeleteContractError",
          },
        },
        [_vm._v(_vm._s(_vm.deleteContractErrorMessage))]
      ),
      _c(
        "b-alert",
        {
          staticClass: "mt-3 mb-4",
          attrs: { variant: "success" },
          model: {
            value: _vm.isDeleteContractSuccess,
            callback: function ($$v) {
              _vm.isDeleteContractSuccess = $$v
            },
            expression: "isDeleteContractSuccess",
          },
        },
        [
          _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("adminComponents.deleteContractOverlay.successMessage")
              ) +
              " "
          ),
        ]
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.editContractOverlay.type")) +
              ": "
          ),
          _c(
            "span",
            { staticClass: "badge badge-soft-primary font-size-12 ml-1" },
            [_vm._v(" " + _vm._s(_vm.contractToDelete.type) + " ")]
          ),
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.editContractOverlay.reference")) +
              ": " +
              _vm._s(_vm.contractToDelete.name) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.editContractOverlay.createdAt")) +
              ": " +
              _vm._s(_vm.formatDate(_vm.contractToDelete.createdAt)) +
              " "
          ),
        ]),
        _c("div", { staticClass: "col-md-6" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("adminComponents.editContractOverlay.createdBy")) +
              ": " +
              _vm._s(_vm.contractToDelete.createdBy) +
              " "
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("adminComponents.deleteContractOverlay.description")
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "row mt-4 mb-3" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              { attrs: { variant: "danger" }, on: { click: _vm.clickDelete } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.deleteContractOverlay.deleteButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "light" },
                on: { click: _vm.clickClose },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.deleteContractOverlay.cancelButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import CompanyService from "@/api/services/company.service.js";
import { getErrorMessageByErrorCode, getDateAccoringTolanguage } from "@/shared/util/helpers.js";
import ActivateOrganizationModal from "@/redem/admin-panel/components/modals/initial-organization-activation-modal.vue";

export default {
  components: {
    ActivateOrganizationModal
  },
  data() {
    return {
      /***** Data variables *****/
      organizations: null,
      dataForTable: null,
      organizationToInitialActivation: null,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      showOrganizationActivationModal: false,
      /***** Table Data *****/
      fieldsForTable: [
        {
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "createdDate",
          label: "Registered Date",
          thStyle: { width: "150px" }
        },
        {
          key: "metaData",
          label: "Organization",
          sortable: true,
          thStyle: { width: "350px" }
        },
        {
          key: "owner",
          label: "Owner"
        },
        {
          key: "action",
          label: "",
          thStyle: { width: "120px" }
        },
        {
          key: "delete",
          label: "",
          thStyle: { width: "50px" }
        },
      ],
      currentPage: 1,
      perPage: 5,
      filter: null
    }
  },
  computed: {
    rows() {
      return this.organizations.length;
    },
  },
  async mounted() {
    await this.fetchNewrganizations();
  },
  methods: {  
    async fetchNewrganizations() {
      this.isComponentLoading = true;
      try {
        this.dataForTable = []
        this.organizations   = await CompanyService.getAllNewCompanies();

        // inster data into table
        this.organizations.forEach(organization => {
          let organizationToPush = {
            id: organization.id,
            createdDate: organization.createdAt,
            metaData: {
              name: organization.name,
              image: organization.image
            },
            owner: {
              id: organization.companyOwner.id,
              name: organization.companyOwner.name.first + ' ' + organization.companyOwner.name.last,
              email: organization.companyOwner.email,
              image: organization.companyOwner.image,
            },
          }

          this.dataForTable.push(organizationToPush)
        });

        this.isComponentLoading = false;
        
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
        this.isComponentLoading = false;
      }
    },
    clickActivateOrganization(organization) {
      this.organizationToInitialActivation = organization;
      this.showOrganizationActivationModal = true;      
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    async closeActivateOrganizationModal() {
      this.showOrganizationActivationModal = false;
      await this.fetchNewrganizations();
    },
  }
}
</script>

<template>
  <div>
    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- No New Organizations -->
     <div class="row pt-5" v-if="!isComponentLoading && (!organizations || organizations.length === 0)">
      <div class="col-md-12 text-center p-5">
        <img src="@/assets/images/information.png" style="height: 60px"/>
        <div class="mt-3"> No New Registrations </div>
      </div>
     </div>

    <!-- Display active organizations in Table -->
    <div v-if="!isComponentLoading && organizations && organizations.length > 0" class="mt-4">

      <!-- Search Bar -->
      <div class="row mb-4">
        <div class="col-md-3">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search"
          ></b-form-input>
        </div>
      </div>

      <!-- Data Table -->
      <div class="table-responsive mb-0">
        <b-table
          :items="dataForTable"
          :fields="fieldsForTable"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :bordered="true"
          :filter="filter"
          :filter-included-fields="['organization']"
        >

          <!-- Date Column -->
          <template v-slot:cell(createdDate)="data">
            {{ formatDate(data.value) }}
          </template>

          <!-- Organization Column -->
          <template v-slot:cell(metaData)="data">
            <div class="row align-items-center">
              <div class="col-md-12">
                <img
                  :src="data.value.image"
                  class="rounded-circle mr-2"
                  style="height: 30px"
                />
                <span style="font-weight: 600"> {{ data.value.name }} </span>
              </div>
            </div>
          </template>

          <!-- Organization Owner -->
          <template v-slot:cell(owner)="data">
            <div class="row align-items-center justify-content-between">
              <div class="col-1">
                <img
                  :src="data.value.image"
                  class="rounded-circle"
                  style="height: 30px"
                />
              </div>
              <div class="col-11">
                <div style="font-weight: 600"> {{ data.value.name }} </div>
                <small> {{ data.value.email }} </small>
              </div>
            </div>
          </template>

          <!-- Action -->
          <template v-slot:cell(action)="scope">
            <b-button variant="primary" size="sm" @click="clickActivateOrganization(scope.item)">
              <i class='bx bx-check mr-2' style="font-size: medium;"></i> Activate
            </b-button>
          </template>

          <!-- Delete -->
          <template v-slot:cell(delete)="scope">
            <i style="font-size: 15px; cursor: pointer;" class="bx bx-trash" @click="clickDeleteOrganization(scope.item)"></i>
          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div class="row">
        <div class="col">
          <div
            class="
              dataTables_paginate
              paging_simple_numbers
              float-right
            "
          >
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Organization Activation Modal -->
    <ActivateOrganizationModal
      v-if="showOrganizationActivationModal"
      :visible="showOrganizationActivationModal"
      :organization="organizationToInitialActivation"
      @close="closeActivateOrganizationModal()"
    />     
  </div>
</template>


import { ApiCall } from "../apiBase";

/******* Get All Packages *******/
class PackageService {
  async getAllPackages() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
        query getAllPackages {
          getAllPackages {
            id
            name
            duration
            numberOfCredits
            pricePerCredit
          }
        }
        `,
        operationName: "getAllPackages"
      }
    });

    if (response?.data?.data?.getAllPackages && !response?.data?.errors) {
      return response.data.data.getAllPackages;
    } else {
      return response?.data?.errors;
    }
  }

  /***** Get Package by ID *****/
  async getPackageDetails(packageID) {
    let packageDetails = null;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query {
            getPackage(packageId: "${packageID}"){
              id
              name
              numberOfUsers
              pricePerCreditMonth
              pricePerCreditYear
              pricePerCreditOther
              packageType
            }
          }
        `
      }
    });

    if (result) {
      packageDetails = result.data.data.getPackage;
    }

    return packageDetails;
  }

  /******** Set Package ************/
  async setPackageToCompany(companyID, packageID) {
    let packageUpdated = false;

    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation setPackage($companyId: String!, $packageId: ID!) {
            setPackage (companyId: $companyId, packageId: $packageId) {
              id
              name
              isActivated
              packageId
              createdDate
              walletId
              numberOfEmployees
              address
              image
            }
          }
        `,
        variables: {
          companyId: companyID,
          packageId: packageID
        }
      }
    });

    if (result) {
      packageUpdated = true;
    }

    return packageUpdated;
  }
}

export default new PackageService();

<script>
import { getDateAccoringTolanguage, calculateNumberOfDaysBetweenTwoDates, getErrorMessageByErrorCode, getDateAfterDuration} from "@/shared/util/helpers.js";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
import ContractStatus from '@/shared/enums/contractStatus';
import ContractService from '@/api/services/contract.service.js';
import ResponseStatus from "@/shared/enums/responseStatus";
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    contractToEdit: Object,
    organizationActiveContracts: Object
  },
  components: {
    DatePicker
  },
  data() {
    return {
      /******** Data Variables ******/
      contractStatusEnum: ContractStatus,
      contractStartDate: null,
      contractEndDate: null,
      isAutoRenew: false,
      /******** Operational Variables *******/
      isEditContractSuccess: false,
      isEditContractError: false,
      editContractErrorMessage: null,
    };
  },
  validations: {
    contractStartDate: { required },
    contractEndDate: { required }
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    this.contractStartDate = new Date(this.contractToEdit.startDate);
    this.contractEndDate = new Date(this.contractToEdit.endDate);
    this.isAutoRenew = this.contractToEdit.autoRenew;
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    disabledStartDates(date) {
      // Disable dates before active contract end date
      let dateLimitation = null
      
      if(this.contractToEdit.status === ContractStatus.UPCOMING && this.organizationActiveContracts && this.organizationActiveContracts.endDate) {
        dateLimitation = new Date(this.organizationActiveContracts.endDate);
      } else {
        dateLimitation = new Date();
      }
      
      dateLimitation.setHours(23, 59, 59, 59);
      return date < dateLimitation;
    },
    disabledEndDates(date) {
      let today = new Date();
      let dateLimitation = today > this.contractStartDate ? today : this.contractStartDate;
      return date < dateLimitation;
    },
    getNumberOfDaysBetweenTwoDates(startDate, endDate) {
      return calculateNumberOfDaysBetweenTwoDates(startDate, endDate);
    },
    clickSave() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isEditContractError = true;
        this.editContractErrorMessage = this.$t("adminComponents.editContractOverlay.validationError");
      } else {
        this.editContract();
      }
    },
    async editContract() {
      const editContractInput = {
        contractId: this.contractToEdit.id,
        startDate: this.contractStartDate,
        endDate: this.contractEndDate,
        autoRenew: this.isAutoRenew
      }

      try {
        let response = await ContractService.editContract(editContractInput);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isEditContractSuccess = true;
          setTimeout(() => {
            this.isEditContractSuccess = false;
            this.$emit("close");
            EventBus.$emit("refreshOrganizationContractsWidgets");
          }, 2000);
        }
      } catch (error) {
        this.isEditContractError = true;
        this.editContractErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    selectNewStartDate(newStartDate) {
      // set new contract end date by keeping same duration as the old one
      if (newStartDate && this.contractToEdit.startDate && this.contractToEdit.endDate) {
        let duration = this.getNumberOfDaysBetweenTwoDates(this.contractToEdit.startDate, this.contractToEdit.endDate);
        this.contractEndDate = getDateAfterDuration(newStartDate, duration, true)
      } 
    },
    clickDeleteContract() {
      this.$emit("close");
      EventBus.$emit("deleteContract", this.contractToEdit);
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row d-flex align-items-center">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.editContractOverlay.title")}} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Contract Meta Information -->
    <div class="mt-4">
      <div class="row">
        <div class="col-md-6">
          {{$t("adminComponents.editContractOverlay.type")}}: <span class="badge badge-soft-primary font-size-12 ml-1"> {{contractToEdit.type}} </span>
        </div>
        <div class="col-md-6">
          {{$t("adminComponents.editContractOverlay.reference")}}: {{contractToEdit.name}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          {{$t("adminComponents.editContractOverlay.createdAt")}}: {{ formatDate(contractToEdit.createdAt)}}
        </div>
        <div class="col-md-6">
          {{$t("adminComponents.editContractOverlay.createdBy")}}: {{contractToEdit.createdBy}}
        </div>
      </div>

      <hr/>
    </div>

    <!-- Messages -->
     <div>
      <!-- Error Message -->
      <b-alert
        v-model="isEditContractError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ editContractErrorMessage }}</b-alert
      >

      <!-- Success Message -->
      <b-alert
        v-model="isEditContractSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.editContractOverlay.updateSuccess")}}
      </b-alert>
     </div>

    <!-- Form -->
    <div class="row form-group mt-4">
      <div class="col-md-6">
        <label for="contractStartDate">
          {{$t("adminComponents.editContractOverlay.startDate.label")}}
          <span class="text-danger">*</span>
        </label>
        <date-picker
          id="contractStartDate"
          v-model="contractStartDate"
          :placeholder="$t('adminComponents.editContractOverlay.startDate.placeholder')"
          format="DD.MM.YYYY"
          :disabled-date="disabledStartDates"
          :disabled="contractToEdit.status === contractStatusEnum.ACTIVE"
          :class="{
            'is-invalid': $v.contractStartDate.$error
          }"
          @input="selectNewStartDate($event)"
        />
        <div v-if="$v.contractStartDate.$error" class="invalid-feedback">
          <span v-if="!$v.contractStartDate.required"> {{ $t("adminComponents.editContractOverlay.startDate.required")}} </span>
        </div>
      </div>
      <div class="col-md-6">
        <label for="contractEndDate">
          {{ $t("adminComponents.editContractOverlay.endDate.label") }}
          <span class="text-danger">*</span>
        </label>
        <date-picker
          id="contractEndDate"
          v-model="contractEndDate"
          :placeholder="$t('adminComponents.editContractOverlay.endDate.placeholder')"
          format="DD.MM.YYYY"
          :disabled="!contractStartDate"
          :disabled-date="disabledEndDates"
          :class="{
            'is-invalid': $v.contractEndDate.$error
          }"
        />
        <div v-if="$v.contractEndDate.$error" class="invalid-feedback">
          <span v-if="!$v.contractEndDate.required"> {{ $t("adminComponents.editContractOverlay.endDate.required")}} </span>
        </div>
      </div>
    </div>

    <!-- Contract duration -->
    <div v-if="contractEndDate && contractStartDate" class="row mb-4 col-md-12">
      {{ $t("adminComponents.editContractOverlay.days1") }} {{ getNumberOfDaysBetweenTwoDates(contractStartDate,contractEndDate) }} {{ $t("adminComponents.editContractOverlay.days2") }}
    </div>

    <!-- Contract automatic renew -->
    <div class="form-group row mt-4 col-md-12">
      <b-form-checkbox
        v-model="isAutoRenew"
        label="Auto Renew"
        name="autoRenew"
        id="autoRenew"
      >
        {{ $t("adminComponents.editContractOverlay.autoRenew") }}
      </b-form-checkbox>
    </div>

    <!-- Actions -->
    <div class="row mt-5">
      <div class="col-md-12">
        <b-button variant="primary" @click="clickSave">
          {{ $t("adminComponents.editContractOverlay.saveButton") }}
        </b-button>
        <b-button v-if="contractToEdit.status === contractStatusEnum.UPCOMING" variant="light" class="ml-2" @click="clickDeleteContract">
          {{ $t("adminComponents.editContractOverlay.deleteButton") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>
.is-invalid {
  border-radius: 5px;
  border: 1px solid red;
}

</style>
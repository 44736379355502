var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group row mb-4" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c(
            "b-form-radio-group",
            {
              attrs: { id: "creditPlanCategory" },
              model: {
                value: _vm.creditPlanCategory,
                callback: function ($$v) {
                  _vm.creditPlanCategory = $$v
                },
                expression: "creditPlanCategory",
              },
            },
            [
              _c(
                "b-form-radio",
                {
                  staticClass: "mt-2",
                  attrs: { value: _vm.CreditPlanCategories.EXPIRING },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlanInfoForm.creditPlanCategory.expiring"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-form-radio",
                {
                  staticClass: "mt-2",
                  attrs: {
                    value: _vm.CreditPlanCategories.NON_EXPIRING,
                    disabled: !_vm.isCustomContract,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlanInfoForm.creditPlanCategory.nonExpiring"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "b-form-radio",
                {
                  staticClass: "mt-2",
                  attrs: {
                    value: _vm.CreditPlanCategories.PAY_AS_USAGE,
                    disabled: !_vm.isCustomContract,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlanInfoForm.creditPlanCategory.payAsUsage"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "form-group row mb-4 mt-4" }, [
      _vm.creditPlanCategory !== _vm.CreditPlanCategories.PAY_AS_USAGE
        ? _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { attrs: { for: "creditAmount" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.creditPlanInfoForm.credits.label")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
              ]),
              _c("b-form-input", {
                class: {
                  "is-invalid": _vm.$v.creditAmount.$error,
                },
                attrs: {
                  id: "creditAmount",
                  type: "number",
                  min: "0",
                  placeholder: _vm.$t(
                    "adminComponents.creditPlanInfoForm.credits.placeholder"
                  ),
                },
                model: {
                  value: _vm.creditAmount,
                  callback: function ($$v) {
                    _vm.creditAmount = $$v
                  },
                  expression: "creditAmount",
                },
              }),
              _vm.$v.creditAmount.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    !_vm.$v.creditAmount.required
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.creditPlanInfoForm.credits.requiredError"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    !_vm.$v.creditAmount.minValue
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.creditPlanInfoForm.credits.minValueError"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("label", { attrs: { for: "pricePerCredit" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "adminComponents.creditPlanInfoForm.pricePerCredit.label"
                  )
                ) +
                " "
            ),
          ]),
          _c("b-form-input", {
            attrs: {
              id: "pricePerCredit",
              type: "number",
              min: "0",
              placeholder: _vm.$t(
                "adminComponents.creditPlanInfoForm.pricePerCredit.placeholder"
              ),
            },
            model: {
              value: _vm.pricePerCredit,
              callback: function ($$v) {
                _vm.pricePerCredit = $$v
              },
              expression: "pricePerCredit",
            },
          }),
        ],
        1
      ),
    ]),
    _vm.creditPlanCategory !== _vm.CreditPlanCategories.PAY_AS_USAGE
      ? _c("div", { staticClass: "form-group row mb-3" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { attrs: { for: "paymentAmount" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.creditPlanInfoForm.payment.label")
                    ) +
                    " "
                ),
              ]),
              _c("b-form-input", {
                attrs: {
                  id: "paymentAmount",
                  type: "number",
                  min: "0",
                  placeholder: _vm.$t(
                    "adminComponents.creditPlanInfoForm.payment.placeholder"
                  ),
                },
                model: {
                  value: _vm.paymentAmount,
                  callback: function ($$v) {
                    _vm.paymentAmount = $$v
                  },
                  expression: "paymentAmount",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _vm.isPageLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isPageLoading && _vm.organization
      ? _c(
          "div",
          [
            _c("PageHeader", {
              attrs: {
                title: "Organization Details",
                items: _vm.computedItems,
              },
            }),
            _c("OrganizationDetailsHeader", {
              staticClass: "mb-4",
              attrs: {
                organization: _vm.organization,
                organizationAdmins: _vm.organizationAdmins,
                organizationStats: _vm.organizationStats,
                isSuperAdminComponent: true,
              },
            }),
            _c(
              "b-tabs",
              { attrs: { "content-class": "p-2" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [
                                  _c("i", {
                                    staticClass: "bx bx-credit-card-alt",
                                    staticStyle: { "font-size": "medium" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.tabTitles.creditAndTransactionManagement"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      108975198
                    ),
                  },
                  [
                    _c("CreditAndTransactionManagement", {
                      attrs: { organization: _vm.organization },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [
                                  _c("i", {
                                    staticClass: "bx bx-file",
                                    staticStyle: { "font-size": "medium" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.tabTitles.contracts"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      647081736
                    ),
                  },
                  [
                    _c("ContractManagement", {
                      attrs: {
                        organization: _vm.organization,
                        isSuperAdminComponent: true,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [
                                  _c("i", {
                                    staticClass: "bx bx-money-withdraw",
                                    staticStyle: { "font-size": "medium" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.tabTitles.creditPlans"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      408908573
                    ),
                  },
                  [
                    _c("CreditPlanManagement", {
                      attrs: { organization: _vm.organization },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { lazy: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [
                                  _c("i", {
                                    staticClass: "bx bx-group",
                                    staticStyle: { "font-size": "medium" },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.tabTitles.userManagement"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.organizationStats.numberOfUsers > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge bg-primary rounded-pill text-white ml-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.organizationStats
                                                  .numberOfUsers
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      613286379
                    ),
                  },
                  [
                    _c("UserManagement", {
                      attrs: {
                        organization: _vm.organization,
                        loggedInUser: _vm.loggedInUser,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.isPageLoading && _vm.isFetchingDataError
      ? _c("div", { staticClass: "row pt-5" }, [
          _c("div", { staticClass: "col-md-12 text-center" }, [
            _vm._v(" " + _vm._s(this.dataFetchingnErrorMessage) + " "),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
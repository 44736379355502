import { render, staticRenderFns } from "./initial-organization-activation-modal.vue?vue&type=template&id=d49258e2&scoped=true"
import script from "./initial-organization-activation-modal.vue?vue&type=script&lang=js"
export * from "./initial-organization-activation-modal.vue?vue&type=script&lang=js"
import style0 from "./initial-organization-activation-modal.vue?vue&type=style&index=0&id=d49258e2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d49258e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d49258e2')) {
      api.createRecord('d49258e2', component.options)
    } else {
      api.reload('d49258e2', component.options)
    }
    module.hot.accept("./initial-organization-activation-modal.vue?vue&type=template&id=d49258e2&scoped=true", function () {
      api.rerender('d49258e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/admin-panel/components/modals/initial-organization-activation-modal.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "row mb-4" }, [
          _c("div", { staticClass: "col-10" }, [
            _c("h5", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.activateOrganization.title"))
              ),
            ]),
          ]),
          _c("div", { staticClass: "col-md-2 text-right" }, [
            _c("i", {
              staticClass: "bx bx-x",
              staticStyle: { "font-size": "x-large", cursor: "pointer" },
              on: { click: _vm.clickClose },
            }),
          ]),
        ]),
        _c("div", { staticClass: "row mb-3 align-items-center" }, [
          _c("div", { staticClass: "col-md-5 text-right" }, [
            _c("img", {
              staticStyle: { width: "50px", height: "50px" },
              attrs: {
                src: _vm.organization.metaData.image,
                alt: "Organization Logo",
              },
            }),
          ]),
          _c("div", { staticClass: "col-md-7" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "15px", "font-weight": "600" } },
              [_vm._v(" " + _vm._s(_vm.organization.metaData.name) + " ")]
            ),
            _c("small", [
              _vm._v(" " + _vm._s(_vm.organization.owner.email) + " "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "row justify-content-center" },
          [
            _c(
              "b-alert",
              {
                staticClass: "mt-3",
                attrs: { variant: "danger", dismissible: "" },
                model: {
                  value: _vm.isOrganizationActivationError,
                  callback: function ($$v) {
                    _vm.isOrganizationActivationError = $$v
                  },
                  expression: "isOrganizationActivationError",
                },
              },
              [_vm._v(_vm._s(_vm.organizationActivationErrorMessage))]
            ),
            !_vm.organizationActivationSuccess
              ? _c(
                  "div",
                  { staticClass: "col-12 mt-3 mb-4" },
                  [
                    _c(
                      "form-wizard",
                      {
                        staticClass: "custom-wizard",
                        attrs: {
                          color: "#005181",
                          "back-button-text": "Previous",
                          "finish-button-text": "Activate",
                          shape: "circle",
                          "step-size": "xs",
                        },
                        on: { "on-complete": _vm.onComplete },
                      },
                      [
                        _c(
                          "tab-content",
                          {
                            attrs: {
                              title: _vm.$t(
                                "adminComponents.activateOrganization.step1"
                              ),
                              "before-change": () => _vm.validateStep("step1"),
                            },
                          },
                          [
                            _c("step1", {
                              ref: "step1",
                              on: { "on-validate": _vm.mergePartialModels },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "tab-content",
                          {
                            attrs: {
                              title: _vm.$t(
                                "adminComponents.activateOrganization.step2"
                              ),
                              "before-change": () => _vm.validateStep("step2"),
                            },
                          },
                          [
                            _c("step2", {
                              ref: "step2",
                              on: { "on-validate": _vm.mergePartialModels },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "tab-content",
                          {
                            attrs: {
                              title: _vm.$t(
                                "adminComponents.activateOrganization.step3"
                              ),
                              "before-change": () => _vm.validateStep("step3"),
                            },
                          },
                          [
                            _c("step3", {
                              ref: "step3",
                              on: { "on-validate": _vm.mergePartialModels },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { slot: "prev", variant: "light" },
                            slot: "prev",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.activateOrganization.actions.previous"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { slot: "next", variant: "primary" },
                            slot: "next",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.activateOrganization.actions.next"
                                )
                              )
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { slot: "finish", variant: "success" },
                            slot: "finish",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.activateOrganization.actions.activate"
                                )
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "col-10 mt-2 mb-2" }, [
                  _c("hr", { staticClass: "mb-4" }),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("h5", { staticClass: "mb-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "adminComponents.activateOrganization.success.title"
                            )
                          )
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "adminComponents.activateOrganization.success.text"
                            )
                          )
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.clickClose },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "adminComponents.activateOrganization.actions.close"
                              )
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "adminComponents.assignUpcomingContractOverlay.title"
                    )
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _vm.isComponentLoading
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isComponentLoading && _vm.hasUpcomingContract
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 text-center p-4" }, [
              _c("img", {
                staticStyle: { height: "60px" },
                attrs: { src: require("@/assets/images/information.png") },
              }),
              _c("div", { staticClass: "mt-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.assignUpcomingContractOverlay.hasUpcomingContract"
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isComponentLoading && !_vm.hasUpcomingContract
        ? _c(
            "div",
            { staticClass: "row justify-content-center" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "mt-3 mb-3",
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.isAssignNewContractError,
                    callback: function ($$v) {
                      _vm.isAssignNewContractError = $$v
                    },
                    expression: "isAssignNewContractError",
                  },
                },
                [_vm._v(_vm._s(_vm.assignNewContractErrorMessage))]
              ),
              !_vm.isAssignNewContractSuccess
                ? _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "form-wizard",
                        {
                          staticClass: "custom-wizard",
                          attrs: {
                            color: "#005181",
                            "back-button-text": "Previous",
                            "finish-button-text": "Activate",
                            shape: "circle",
                            "step-size": "xs",
                          },
                          on: { "on-complete": _vm.onComplete },
                        },
                        [
                          _c(
                            "tab-content",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "adminComponents.assignUpcomingContractOverlay.step1"
                                ),
                                "before-change": () =>
                                  _vm.validateStep("step1"),
                              },
                            },
                            [
                              _c("ContractInfoForm", {
                                ref: "step1",
                                attrs: {
                                  currentActiveContract:
                                    this.currentActiveContract,
                                },
                                on: { "on-validate": _vm.mergePartialModels },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "tab-content",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "adminComponents.assignUpcomingContractOverlay.step2"
                                ),
                                "before-change": () =>
                                  _vm.validateStep("step2"),
                              },
                            },
                            [
                              _c("CreditPlanInfoForm", {
                                ref: "step2",
                                on: { "on-validate": _vm.mergePartialModels },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { slot: "prev", variant: "light" },
                              slot: "prev",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.assignUpcomingContractOverlay.actions.previous"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { slot: "next", variant: "primary" },
                              slot: "next",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.assignUpcomingContractOverlay.actions.next"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { slot: "finish", variant: "success" },
                              slot: "finish",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.assignUpcomingContractOverlay.actions.assign"
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("img", {
                          staticStyle: { height: "60px" },
                          attrs: {
                            src: require("@/assets/images/success-icon.png"),
                          },
                        }),
                        _c("h5", { staticClass: "mt-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.assignUpcomingContractOverlay.success.title"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.assignUpcomingContractOverlay.success.text"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.clickClose },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.assignUpcomingContractOverlay.actions.close"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
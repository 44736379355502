<script>
import ContractService from '@/api/services/contract.service';
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import ContractStatus from '@/shared/enums/contractStatus';
import { FormWizard, TabContent } from "vue-form-wizard";
import ContractInfoForm from "@/shared/components/administrational-components/widgets/contract-info-form.vue";
import CreditPlanInfoForm from "@/shared/components/administrational-components/widgets/credit-plan-info-form.vue";
import CreditPlanCategories from "@/shared/enums/creditPlanCategories.js"
import { EventBus } from "@/shared/util/event-bus.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    organization: Object
  },
  components: {
    FormWizard,
    TabContent,
    ContractInfoForm,
    CreditPlanInfoForm
  },
  data() {
    return {
      /******** Data Variables ******/
      formData: {},
      contracts: null,
      hasUpcomingContract: false,
      currentActiveContract: null,
      /******** Operational Variables *******/
      isComponentLoading: false,
      isFetchingDataError: false,
      isAssignNewContractSuccess: false,
      isAssignNewContractError: false,
      assignNewContractErrorMessage: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    await this.fetchOrganizationContracts();
  },
  methods: {
    clickClose() {
      this.$emit("close");
      EventBus.$emit("refreshOrganizationContractsWidgets");
    },
    mergePartialModels(model, isValid){
      if(isValid){
      // merging each step model into the final model
       this.formData = { ...this.formData, ...model };
       this.$refs.step2.contractType = this.formData.selectedContractType;
       this.$refs.step2.redemPackages = this.formData.redemPackages;
      }
    },    
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    async onComplete() {
      try {
        const assignUpcomingContractDTO = {
          companyId: this.organization.id,
          contract: {
            contractType: this.formData.selectedContractType,
            startDate: this.formData.contractStartDate,
            endDate: this.formData.contractEndDate,
            autoRenew: this.formData.isAutoRenew,
          },
          creditPlan: {
            creditPlanCategory: this.formData.creditPlanCategory,
            creditAmount: this.formData.creditPlanCategory === CreditPlanCategories.PAY_AS_USAGE ? 0 : Number(this.formData.creditAmount),
            pricePerCredit: Number(this.formData.pricePerCredit),
            paymentAmount: this.formData.creditPlanCategory === CreditPlanCategories.PAY_AS_USAGE ? 0 : Number(this.formData.paymentAmount)
          },
        }

        await ContractService.assignUpcomingContract(assignUpcomingContractDTO);
        this.isAssignNewContractSuccess = true;
      } catch(error) {
        this.isComponentLoading = false;
        this.isAssignNewContractError = true;
        this.assignNewContractErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    async fetchOrganizationContracts() {
      this.isComponentLoading = true;
      try {
        this.contracts = null;

        this.contracts = await ContractService.getCompanyContracts(this.organization.id);

        // Check for upcoming contracts based on status
        this.hasUpcomingContract = this.contracts.some(contract => 
          contract.status === ContractStatus.UPCOMING
        );

        this.currentActiveContract = this.contracts.find(contract => contract.status === ContractStatus.ACTIVE);

        await this.setDataForDisplay();
        this.isComponentLoading = false;
      } catch(error) {
        this.isComponentLoading = false;
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
  }
}
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row mb-4">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{ $t("adminComponents.assignUpcomingContractOverlay.title") }} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Loading -->
    <div class="row" v-if="isComponentLoading">
      <div class="col-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display upcomign contract already exist -->
    <div class="row" v-if="!isComponentLoading && hasUpcomingContract">
      <div class="col-12 text-center p-4">
        <img src="@/assets/images/information.png" style="height: 60px" />
        <div class="mt-4"> {{ $t("adminComponents.assignUpcomingContractOverlay.hasUpcomingContract") }} </div>
      </div>
    </div>

    <!-- Display Assign Contract From -->
    <div class="row justify-content-center" v-if="!isComponentLoading && !hasUpcomingContract">
      <b-alert
        v-model="isAssignNewContractError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ assignNewContractErrorMessage }}</b-alert
      >

      <div class="col-12" v-if="!isAssignNewContractSuccess">
        <form-wizard
          @on-complete="onComplete"
          color="#005181"
          back-button-text="Previous"
          finish-button-text="Activate"
          class="custom-wizard"
          shape="circle"
          step-size="xs"
        >
          <tab-content :title="$t('adminComponents.assignUpcomingContractOverlay.step1')" :before-change="()=>validateStep('step1')">
            <ContractInfoForm ref="step1" @on-validate="mergePartialModels" :currentActiveContract="this.currentActiveContract"/>
          </tab-content>
          <tab-content :title="$t('adminComponents.assignUpcomingContractOverlay.step2')" :before-change="()=>validateStep('step2')">
            <CreditPlanInfoForm ref="step2" @on-validate="mergePartialModels"/>
          </tab-content>
          <b-button slot="prev" variant="light" class="mr-2">{{ $t("adminComponents.assignUpcomingContractOverlay.actions.previous") }}</b-button>
          <b-button slot="next" variant="primary">{{ $t("adminComponents.assignUpcomingContractOverlay.actions.next") }}</b-button>
          <b-button slot="finish" variant="success">{{ $t("adminComponents.assignUpcomingContractOverlay.actions.assign") }}</b-button>        
        </form-wizard>
      </div>

      <div class="col-12 mt-4 mb-4" v-else>
        <div class="text-center">
          <img src="@/assets/images/success-icon.png" style="height: 60px" />
          <h5 class="mt-4"> {{ $t("adminComponents.assignUpcomingContractOverlay.success.title") }} </h5>
          <p> {{ $t("adminComponents.assignUpcomingContractOverlay.success.text") }} </p>
          <b-button @click="clickClose" variant="primary"> {{ $t("adminComponents.assignUpcomingContractOverlay.actions.close") }} </b-button>
        </div>
      </div>

    </div>
  </b-modal>
</template>

<style scoped>
.custom-wizard ::v-deep .wizard-card-footer {
  display: flex;
  justify-content: flex-start;
}
</style>
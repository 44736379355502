import ResponseStatus from "@/shared/enums/responseStatus";
import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class CompanyService {
  /************ Get All Companies **********/
  async getAllCompnies() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanies {
            getCompanies {
              id,
              name
              image
              isActivated
              comment
              contract {
                id
                name
                endDate
                startDate
                type
              }
              wallet {
                id
                balance
                status
              }
            }
          }
        `
      }
    });

    if (response?.data?.data?.getCompanies && !response?.data?.errors) {
      return response?.data?.data?.getCompanies;
    } else {
      return response?.data?.errors;
    }
  }

  /********* Update Company Status ******/
  async updateCompanyStatus(companyStatusUpdateObject) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateCompanyStatus($CompanyStatusUpdateInput: CompanyStatusUpdateInput!) {
            updateCompanyStatus(company: $CompanyStatusUpdateInput) {
              name
              image
              address
              isActivated
              contract {
                id
                name
                endDate
                startDate
              }
              wallet {
                id
                balance
                status
              }
            }
          }
        `,
        variables: {
          CompanyStatusUpdateInput: companyStatusUpdateObject
        }
      }
    });

    if (result?.data?.data?.updateCompanyStatus && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Update Company Status to deactivate ******/
  async deactivateCompany(companyStatusUpdateObject) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changeCompanyStatusToDeactivate($CompanyStatusUpdateInput: CompanyStatusUpdateInput!) {
            changeCompanyStatusToDeactivate(company: $CompanyStatusUpdateInput) {
             status
            }
          }
        `,
        variables: {
          CompanyStatusUpdateInput: companyStatusUpdateObject
        }
      }
    });

    if (result?.data?.data?.changeCompanyStatusToDeactivate && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /****** Delete Company *****/
  async deleteCompany(companyId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation deleteCompany($input: ID!) {
            deleteCompany(companyId: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: companyId
        }
      }
    });

    if (result?.data?.data?.deleteCompany && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /****** Change Company Contract Status *****/
  async changeCompanyContractStatus(company) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changeCompanyContractStatus($input: CompanycontractStatusInput!) {
            changeCompanyContractStatus(company: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: company
        }
      }
    });

    if (
      result?.data?.data?.changeCompanyContractStatus &&
      !result?.data?.errors
    ) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Update Company Next Contract ******/
  async updateCompanyNextContract(companyContractUpdateInput) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateCompanyNextContract($input: CompanyContractUpdateInput!) {
            updateCompanyNextContract(input: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: companyContractUpdateInput
        }
      }
    });

    if (
      result?.data?.data?.updateCompanyNextContract &&
      !result?.data?.errors
    ) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /****** Change Company Contract Status *****/
  async updateCompanyComment(company) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateCompanyComment($input: CompanyCommentUpdateInput!) {
            updateCompanyComment(input: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: company
        }
      }
    });

    if (result?.data?.data?.updateCompanyComment && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /********* Assign Contract to Company ******/
  async assignNewContractToCompany(companyContractUpdateInput) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation assignNewContractToCompany($input: CompanyContractUpdateInput!) {
            assignNewContractToCompany(input: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: companyContractUpdateInput
        }
      }
    });

    if (
      result?.data?.data?.assignNewContractToCompany &&
      !result?.data?.errors
    ) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /****** Change Company Beta Status *****/
  async updateCompanyBetaStatus(company) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation changeCompanyBetaStatus($input: CompanyBetaStatusInput!) {
            changeCompanyBetaStatus(input: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: company
        }
      }
    });

    if (result?.data?.data?.changeCompanyBetaStatus && !result?.data?.errors) {
      return ResponseStatus.SUCCESS;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }

  /***** Get Companies Summary *****/
  async getCompaniesSummary() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompaniesSummary {
            getCompaniesSummary {
              numberOfActiveCompanies
              numberOfNewCompanies
            }
          }
        `
      }
    });

    if (response?.data?.data?.getCompaniesSummary && !response?.data?.errors) {
      return response?.data?.data?.getCompaniesSummary;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /***** Get All Active Companies *****/
  async getAllActiveCompanies() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllActiveCompanies {
            getAllActiveCompanies {
              id
              name
              accountManager
              legalName
              image
              supportLevel
              address {
                street
                city
                state
                postalCode
                country
              }
              isActivated
              contract {
                id
                name
                startDate
                endDate
                type
                primaryCreditPlan {
                  id
                  name
                  pricePerCredit
                  isPayPerUsage
                  credits {
                    balance
                    received
                  }
                }
                backupCreditPlan {
                  id
                  name
                  pricePerCredit
                  isPayPerUsage
                  credits {
                    balance
                    received
                  }
                }
              },
              wallet {
                id
                balance
                status
              }
              companyOwner {
                id
                name {
                  first
                  last
                }
                email
                image
              }
              activeCreditOverview {
                totalActiveBalance
                totalReceivedFromPayAsUsage
              }
              latestDataImport {
                datafileId
                createdAt
                daysAgo
              }
            }
          }
        `
      }
    });

    if (response?.data?.data?.getAllActiveCompanies && !response?.data?.errors) {
      return response?.data?.data?.getAllActiveCompanies;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /***** Get All Active Companies *****/
  async getAllNewCompanies() {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllNewCompanies {
            getAllNewCompanies {
              id
              name
              accountManager
              image
              isActivated
              createdAt
              companyOwner {
                id
                name {
                  first
                  last
                }
                email
                image
              }
            }
          }
        `
      }
    });

    if (response?.data?.data?.getAllNewCompanies && !response?.data?.errors) {
      return response?.data?.data?.getAllNewCompanies;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

   /************* get Company by Id *********/
   async getCompanyById(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompany($companyId: ID!){
            getCompany(companyId: $companyId) {
              id
              name
              accountManager
              legalName
              image
              VATNumber
              supportLevel
              address {
                street
                city
                state
                postalCode
                country
              }
              isActivated
              contract {
                id
                name
                startDate
                endDate
              },
              wallet {
                id
                balance
                status
              }
              companyOwner {
                id
                name {
                  first
                  last
                }
                email
                image
              }
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompany && !response?.data?.errors) {
      return response?.data?.data?.getCompany;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* get Users of a company *********/
  async getUsersOfCompany(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyUsers($companyId: ID!){
            getCompanyUsers(companyId: $companyId) {
              id,
              name {
                first,
                last
              }
              image,
              email,
              accessLevel,
              status
              magicKey
              numberOfImports
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyUsers && !response?.data?.errors) {
      return response?.data?.data?.getCompanyUsers;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Update Company ***********/
  async updateCompany(companyUpdateObject) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation updateCompany($CompanyUpdateInput: CompanyUpdateInput!) {
            updateCompany(companyToUpdate: $CompanyUpdateInput) {
              status
              message
            }
          }
        `,
        variables: {
          CompanyUpdateInput: companyUpdateObject
        }
      }
    });

    if (response?.data?.data?.updateCompany && !response?.data?.errors) {
      return response?.data?.data?.updateCompany;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* get Presigned URL for Company Avatart *************/
  async getCompanyAvatarPreSignedURL(fileName, companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyAvatarUploadURL($fileName: String!, $companyId: ID!) {
            getCompanyAvatarUploadURL(imageKey: $fileName, companyId: $companyId) {
              signedUrl
            }
          }
        `,
        variables: {
          fileName: fileName,
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyAvatarUploadURL && !response?.data?.errors) {
      return response?.data?.data?.getCompanyAvatarUploadURL;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Get Active Company Admin Users *********/
  async getActiveCompanyAdminUsers(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getActiveCompanyAdminUsers($companyId: ID!){
            getActiveCompanyAdminUsers(companyId: $companyId) {
              id,
              name {
                first,
                last
              }
              image,
              email,
              accessLevel,
              status
              magicKey
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getActiveCompanyAdminUsers && !response?.data?.errors) {
      return response?.data?.data?.getActiveCompanyAdminUsers;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Get Company Stats *********/
  async getCompanyStats(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyStats($companyId: ID!){
            getCompanyStats(companyId: $companyId) {
              numberOfDataImports
              numberOfDataRecords
              numberOfUsers
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyStats && !response?.data?.errors) {
      return response?.data?.data?.getCompanyStats;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /****** Activate Organization *****/
  async activateOrganization(activateOrganizationInput) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation activateOrganization($input: ActivateOrganizationInput!) {
            activateOrganization(input: $input) {
              status
              message
            }
          }
        `,
        variables: {
          input: activateOrganizationInput
        }
      }
    });

    if (response?.data?.data?.activateOrganization && !response?.data?.errors) {
      const {status, message} = response.data.data.activateOrganization;
      return {status, message};
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }
}

export default new CompanyService();
